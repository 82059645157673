<template>
    <div class="success-block">
        <img src="/icon/success_modal.svg" class="icon-success" alt="success" />
        <div>
            <h2 class="success-title">{{ consultFormData.message }}</h2>
        </div>
        <span class="success-description">{{ $t('successModal.successDescription') }}</span>
    </div>
</template>

<script setup lang="ts">
import { useContentPages } from '~/stores/homeStores';

const contentPagesStore = useContentPages();
const consultFormData = computed(() => contentPagesStore.getContactFormData);
</script>

<style lang="scss" scoped>
.success-body {
    max-width: 480px;
}
.icon-success {
    width: 5rem;
    height: 5rem;
}
.success-title {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    text-align: center;
}
.success-description {
    color: var(--main-black-2-b-2-b-2-b, #2b2b2b);
    font-family: Roboto;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.625rem;
    text-align: center;
}
.success-block {
    display: flex;
    flex-direction: column;
    gap: 2.25rem;
    justify-content: center;
    align-items: center;
    max-width: 22.5rem;
    width: 100%;
}
</style>
